import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Container } from "react-bootstrap";
import Layout from "@layout";
import SEO from "@components/seo";
import PageBreadcrumb from "../../components/pagebreadcrumb";
import Profile from "../../components/Profile";
import { normalizedData } from "@utils/functions";
import jsonDataInit from "../../data/about/vision/jsonData.json";
import axios from "axios";
import { useEffect, useState } from "react";
import Preload from "../../components/preloadPage/preload";

const VisionMission = ({ data, location, pageContext }) => {
    const [jsonData, setJsonData] = useState(jsonDataInit);
    const [preload, setPreload] = useState(true);
    useEffect(() => {
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a6dfb46ac35807d3a2c0f5"
        )
            .then((resultData) => {
                setJsonData(resultData.data);
                setPreload(false);
            })
            .catch((err) => {
                console.log(err);
                setPreload(false);
            });
    }, []);
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            {" "}
            {preload && <Preload />}
            <SEO title="Vision & Mission" pathname="/" />
            <PageBreadcrumb
                pageContext={pageContext}
                location={location}
                title="Vision & Mission"
            />
            <Container>
                <h4
                    style={{
                        color: "#470880",
                        paddingBottom: "0px",
                        lineHeight: "130%",
                    }}
                >
                    {jsonData[0].title}
                </h4>
                <p
                    style={{
                        color: "#000",
                        fontSize: "20px",
                        lineHeight: "130%",
                    }}
                >
                    {jsonData[0].title1}
                </p>
                <p
                    style={{
                        color: "#000",
                        paddingLeft: "10px",
                        lineHeight: "130%",
                    }}
                >
                    {jsonData[0].text}
                </p>
                <h4 style={{ color: "#470880", lineHeight: "130%" }}>
                    {jsonData[1].title}
                </h4>
                <p
                    style={{
                        color: "#000",
                        fontSize: "20px",
                        lineHeight: "130%",
                    }}
                >
                    {jsonData[1].title1}
                </p>
                <ul
                    style={{
                        lineHeight: "130%",
                        paddingBottom: "10px",
                    }}
                >
                    <li style={{ color: "#000", paddingLeft: "10px" }}>
                        {jsonData[1].text}
                    </li>
                    <li style={{ color: "#000", paddingLeft: "10px" }}>
                        {jsonData[1].text1}
                    </li>
                    <li style={{ color: "#000", paddingLeft: "10px" }}>
                        {jsonData[1].text2}
                    </li>
                    <li style={{ color: "#000", paddingLeft: "10px" }}>
                        {jsonData[2].text}
                    </li>
                    <li style={{ color: "#000", paddingLeft: "10px" }}>
                        {jsonData[2].text1}
                    </li>
                </ul>
            </Container>
        </Layout>
    );
};
VisionMission.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allArticle: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export const query = graphql`
    query VisionMissionQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        allArticle {
            nodes {
                ...Articles
            }
        }
    }
`;

export default VisionMission;
